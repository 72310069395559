<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <div class="col-12">IOSS</div>
          </div>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="4">
              <CInput
                description="Başlangıç Tarihi"
                type="date"
                horizontal
                v-model="startDate"
              />
            </CCol>
            <CCol col="4">
              <CInput
                description="Bitiş Tarihi"
                type="date"
                horizontal
                v-model="endDate"
              />
            </CCol>
            <CCol col="4">
              <CInput
                description="Sipariş No"
                type="text"
                horizontal
                v-model="orderNumber"
              />
            </CCol>
            <CCol col="4">
              <CSelect
              description="Kargo Firması"
                col="12"
                style="margin-bottom: 0; padding: 0;"
                :options="shippingCompanies"
                :value.sync="shippingCompanyId"
              />
            </CCol>
            <CCol col="4">
              <CSelect
                description="Ülke Seçimi"
                col="12"
                style="margin-bottom: 0; padding: 0;"
                :options="VATCountries"
                :value.sync="VATCountry"
              />
            </CCol>
            <CCol col="4">
              <CButton color="success" @click="getvatOrders(1,0,10,startDate, endDate, orderNumber, shippingCompanyId, VATCountry)">Listele</CButton>&nbsp;&nbsp;
              <CButton color="primary" @click="getExcelByVatOrders">Excel'e Aktar</CButton>&nbsp;&nbsp;
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardBody>
          <CRow>
            <CCol col="12">
              <CDataTable
                :items="vatOrders"
                :fields="fields"
                :loading="loading"
                :items-per-page="itemsPerPage"
                hover
                clickable-rows
                v-on:refresh="getvatOrders(1,0,10,startDate, endDate, orderNumber, shippingCompanyId)"
              >
                <template #createTime="{item}"> 
                  <td> {{ item.createTime && dateFormat(item.createTime) }} </td>
                </template>
                <template #vatPaidByCarrierFlag="{item}"> 
                  <td>
                    
                    <a v-if="item.vatPaidByCarrierFlag"  @click="openModalEvent('update', 'confirm', item, 'Carrier Paid Vat', 'Taşıyıcıya ödenen vergiyi (Carrier Paid Vat) geri çekmek üzeresiniz. Bunu yapmak istediğinize emin misiniz?')" >
                      <CIcon class="green" name="cil-check-alt" />
                    </a>

                    <a v-else @click="openModalEvent('update', 'confirm', item, 'Carrier Paid Vat', 'Taşıyıcı vergi ödemesini (Carrier Paid Vat) tamamlamak üzeresiniz. Bunu yapmak istediğinize emin misiniz?')" >
                      <CIcon name="cil-x" class="red" />
                    </a>

                  </td>
                </template>
              </CDataTable>
              <CPagination
                v-show="pages>1"
                :pages="pages"
                :active-page.sync="activePage"
              />
              <CDataTable
                class="d-none"
                ref="externalAgent"
                :loading="loading"
                :items="vatOrders.slice(0)"
                :items-per-page="itemsPerPage"
                hover
                :active-page="activePage"
              />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="operationEvent"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      ref="confirm"
    />
  </CRow>
</template>
<script>


import moment from "moment";
import ConfirmModal from '../components/confirmModal'

export default {
  name: 'VarOrders',
  data() {
    return {
      activePage: 1,
      pages: 0,
      itemsPerPage: 10,
      orderNumber: null,
      modalTitle: '',
      actionType: '',
      module: 'orders',
      form: 'confirm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      shippingCompanyId: undefined,
      fields: [
        {key: 'id', label: 'Sipariş No', _style: 'font-size:12px'},
        {key: 'createTime', label: 'Sipariş Tarihi', _style: 'font-size:12px'},
        {key: 'countryName', label: 'Ülke', _style: 'font-size:12px'},
        {key: 'countryCode', label: 'Ülke Kodu', _style: 'font-size:12px'},
        {key: 'totalPrice', label: 'Sipariş Tutarı', _style: 'font-size:12px'},
        {key: 'totalVatAmount', label: 'VAT Tutarı', _style: 'font-size:12px'},
        {key: 'trackingNumber', label: 'AWB Number', _style: 'font-size:12px'},
        {key: 'shippingCompanyName', label: 'Kargo Firması', _style: 'font-size:12px'},
        {key: 'vatPaidByCarrierFlag', label: 'Carrier Paid Vat', _style: 'font-size:12px'}

      ],
      VATCountries: [
        {label: "Tüm Ülkeler", value: null},
        {label: "Sadece EU", value: "EU"},
        {label: "Sadece UK", value: "GB"}
      ],
      startDate: '',
      endDate: '',
    }
  },
  watch:{
    activePage: function(newVal){
      this.getvatOrders(newVal,((newVal - 1) * 10),10,this.startDate, this.endDate, this.orderNumber, this.shippingCompanyId, this.VATCountry)
    }
  },
  computed: {
    vatOrders() {
      return this.$store.getters.vatOrders;
    },
    loading() {
      return this.$store.getters.orderLoading;
    },
    shippingCompanies: function(){
      let data = [{label: "Seçiniz", value: undefined}]
      this.$store.getters.shippingCompanyList.map(r => data.push({value:r.id, label: r.name}))
      return data
    },
  },
  components: { ConfirmModal },
  methods: {
    dateFormat(date){
      return moment(date).format("DD.MM.YYYY HH:mm:ss")
    },
    async openModalEvent(actionType, form, data, title, desc){
      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },
    async getvatOrders(page, start, limit, startDate, endDate, orderNumber, shippingCompanyId, countryCode) {
      let params = {
        "page": page,
        "start": start,
        "limit": limit,
        "startDate":  moment(startDate).format('YYYY-MM-DDTHH:mm:ss:SSZ'),
        "endDate": moment(endDate).format('YYYY-MM-DDTHH:mm:ss:SSZ'),
        "orderNumber": orderNumber,
        "shippingCompanyId": shippingCompanyId,
        "countryCode": countryCode
      }

      if(countryCode != null)
        params.countryCode = countryCode;

      await this.$store.dispatch('order_vatOrders', params)
      if(this.$store.getters.vatOrderCount > 10){
        this.pages = Math.ceil(this.$store.getters.vatOrderCount / 10)
      }
    },
    async operationEvent(item) {
      let params = {
        orderNumber: item.id,
        vatPaidByCarrierFlag: !item.vatPaidByCarrierFlag,
      }
      await this.$store.dispatch('order_vatOrderPaidCarrier', params)

      if(this.$store.getters.orderStatus.success){
        this.getvatOrders(1,0,10,this.startDate, this.endDate, this.orderNumber, this.shippingCompanyId, this.VATCountry);
        this.openModalEvent()
      }

    },
    async getExcelByVatOrders() {
      await this.$store.dispatch('order_vatOrdersExcel', {responseType: 'blob'})
      if(this.$store.getters.orderStatus){
        const url = window.URL.createObjectURL(new Blob([this.$store.getters.orderStatus]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'vatOrders_' + moment(new Date).format('DD/MM/YYYY HH:mm:ss') + '.xls');
        document.body.appendChild(link);
        link.click();
      }
    },
  },
  created() {
    var today = new Date();
    this.endDate = moment(today).format('YYYY-MM-DD');
    this.startDate = moment(today).format('YYYY-MM-DD');
    this.getvatOrders(1,0,10,this.startDate, this.endDate, this.orderNumber, this.shippingCompanyId, this.VATCountry);
    this.$store.dispatch('shippingCompany_list')
  }
}
</script>
